<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <v-btn
        v-on="on"
        icon
        @click="toggleAudio"
      >
        <v-icon
          :size="25"
          color="blue darken-2"
        >
          mdi-play-circle-outline
        </v-icon>
      </v-btn>
    </template>

    Ouvir áudio
  </v-tooltip>
</template>

<script>
import PageBus from '@/components/page/PageBus'

export default {
  props: {
    id: {},

    odd: {},
    color: {},
    showRevisor: {},

    album: {},
    aprovado: {},
    assunto: {},
    data: {},
    dirigente: {},
    duracao: {},
    grau: {},
    nucleo: {},
    orador: {},
    sessao: {},
    sessaoId: {}
  },

  methods: {
    toggleAudio () {
      const vm = this

      PageBus.$emit('audioPlay', {
        id: vm.id,
        data: vm.data,
        album: vm.album,
        grau: vm.grau,
        nucleo: vm.nucleo,
        orador: vm.orador,
        revisao: vm.revisao,
        sessaoId: vm.sessaoId,
        sessao: vm.sessao,
        duracao: vm.duracao,
        assunto: vm.assunto,
        dirigente: vm.dirigente
      })
    }
  }
}
</script>
