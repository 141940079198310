<template>
  <div>
    <v-list-item dense>
      <v-list-item-avatar>
        <AudioPlayItem
          :id="audio.id"
          :album="audio.albumNome"
          :album-id="audio.albumId"
          :aprovado="audio.revisaoStatus"
          :assunto="audio.assunto"
          :data="audio.data"
          :dirigente="audio.dirigenteNome"
          :duracao="audio.duracao"
          :grau="audio.grauSigla"
          :nucleo="audio.nucleoNome"
          :orador="audio.oradorNome"
          :sessao-id="audio.sessao"
          :sessao="audio.sessaoDescricao"
        />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ audio.assunto }}</v-list-item-title>
        <v-list-item-subtitle>{{ `${durationFormated} - ${oradorNome}` }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-content v-if="audio.resumo">
        <v-list-item-subtitle>{{ audio.resumo }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-spacer />

      <v-btn
        small
        color="primary"
        :disabled="isNewAudio"
        @click="$emit('add')"
      >
        <v-icon small>
          mdi-plus
        </v-icon>
        Adicionar
      </v-btn>
    </v-list-item>
    <v-divider />
  </div>
</template>
<script>
import { formatSecs } from '@/utils'
import AudioPlayItem from './AudioPlayItem'
import capitalize from 'capitalize-pt-br'

export default {
  components: {
    AudioPlayItem
  },

  props: {
    audio: {},
    trackList: {}
  },

  computed: {
    oradorNome () {
      const audio = this.audio
      return [
        audio.grauSigla,
        capitalize(audio.dirigenteNome || audio.oradorNome || audio.autorNome),
        capitalize(audio.nucleoNome || ''),
        capitalize(audio.albumNome || ''),
        capitalize(audio.sessaoDescricao || '')
      ].filter(v => v).join(' - ').replace('Núcleo ', '').replace('Sessão de ', '').trim()
    },

    durationFormated () {
      return formatSecs(this.audio.duracao)
    },

    isNewAudio () {
      const vm = this
      return Boolean(vm.trackList.find(({ audioId, id }) => [audioId, id].includes(vm.audio.id)))
    }
  }
}
</script>
