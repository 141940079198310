<template>
  <v-data-table
    dense
    :headers="headers"
    :items="tracks"
    disable-pagination
    hide-default-footer
    item-key="id"
  >
    <template v-slot:top>
      <v-toolbar
        dark
        dense
        color="blue-grey darken-2"
      >
        <v-toolbar-title>
          Faixas
        </v-toolbar-title>

        <v-spacer />

        <AlbumTrackList
          v-model="dialog"
          :track-list="tracks"
          :show-edit-fields="showEditFields"
          @add="handleNewAudio"
        />
      </v-toolbar>
    </template>

    <template #body="props">
      <Draggable
        v-model="tracks"
        tag="tbody"
        class="list-group"
        v-bind="dragOptions"
        handle=".handle"
        @start="drag = true"
        @end="drag = false"
      >
        <tr
          v-for="(item, index) in props.items"
          :class="index % 2 ? 'even' : 'odd'"
          :key="index"
          :item="item"
          :headers="headers"
        >
          <td v-if="showEditFields">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <div
                  v-on="on"
                  class="row list-grab"
                >
                  <div class="col handle">
                    <v-icon size="small">
                      mdi-format-align-justify
                    </v-icon>
                  </div>
                  <div class="col">
                    {{ index + 1 }}
                  </div>
                </div>
              </template>
              <span>Clique e Arraste para mudar a ordem da faixa</span>
            </v-tooltip>
          </td>
          <td v-else>
            <div class="col">
              {{ index + 1 }}
            </div>
          </td>

          <td>
            {{ item.assunto }}
            <br>
            <small>{{ formatSecs(item.duracao) }} {{ labelArquivoTipo(item) }} {{ item.oradorNome ? ` de ${item.oradorNome} ` : '' }} ({{ item.tipoDescricao }})</small>
          </td>

          <td>
            <div class="audio">
              <AudioPlayItem
                :id="item.id"
                :album="item.albumNome"
                :album-id="item.albumId"
                :aprovado="item.revisaoStatus"
                :assunto="item.assunto"
                :data="item.data"
                :dirigente="item.dirigenteNome"
                :duracao="item.duracao"
                :grau="item.grauSigla"
                :nucleo="item.nucleoNome"
                :orador="item.oradorNome"
                :sessao-id="item.sessao"
                :sessao="item.sessaoDescricao"
              />
            </div>
          </td>

          <td>
            <v-tooltip
              bottom
              v-if="showEditFields"
            >
              <template #activator="{ on }">
                <v-btn
                  class="pa-2 mr-1"
                  v-on="on"
                  outlined
                  x-small
                  color="red lighten-2"
                  no
                >
                  <v-icon
                    @click="tracks.splice(index, 1)"
                    small
                  >
                    mdi-close-circle-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>Remover</span>
            </v-tooltip>
          </td>
        </tr>
      </Draggable>
    </template>
  </v-data-table>
</template>

<script>
import Draggable from 'vuedraggable'
import { formatSecs } from '@/utils'
import AlbumTrackList from '@/views/Dashboard/AlbumTrackList.vue'
import AudioPlayItem from '@/views/Dashboard/AudioPlayItem'
import { ACTION_AUDIO_SEARCH } from '@/constants'

export default {
  components: {
    Draggable,
    AudioPlayItem,
    AlbumTrackList
  },

  props: {
    value: {},
    albumId: {},
    albumTracks: {},
    showEditFields: {}
  },

  data () {
    return {
      formatSecs,
      tracks: Array.isArray(this.value) ? this.value : [],
      dialog: false,
      headers: [
        { text: '#', width: '50px', align: 'right', sortable: false },
        { text: 'Assunto' },
        { text: '', width: '50px' },
        { text: '', width: '30px', sortable: false }
      ]
    }
  },

  mounted () {
    this.loadTracks()
  },

  computed: {
    dragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },

  methods: {
    loadTracks () {
      const params = {
        sortBy: 'id',
        order: 'ASC',
        album: this.albumId,
        limit: 9999999
      }

      this.$store.dispatch(ACTION_AUDIO_SEARCH, params)
    },

    labelArquivoTipo (track) {
      const arquivoType = String(track.arquivoType || '').trim()
      if (arquivoType.startsWith('audio/')) return 'Áudio'
      if (arquivoType.startsWith('video/')) return 'Vídeo'
      return ''
    },

    handleNewAudio (audio) {
      const newAudio = {
        audioId: audio.id,
        assunto: audio.assunto,
        duracao: audio.duracao,
        arquivoType: audio.arquivoType,
        tipoDescricao: audio.tipoDescricao
      }

      const albumTracks = Array.isArray(this.albumTracks) ? this.albumTracks : []
      const existsAudio = albumTracks.find(({ audioId }) => audioId === audio.id)
      if (existsAudio) Object.assign(newAudio, { albumFaixaId: existsAudio.albumFaixaId })

      this.tracks.push(newAudio)
    }
  },

  watch: {
    value: {
      deep: true,
      handler (value) {
        if (JSON.stringify(value) === JSON.stringify(this.tracks)) return
        this.tracks = Array.isArray(value) ? value : []
      }
    },

    tracks: {
      deep: true,
      handler (tracks) {
        if (JSON.stringify(tracks) === JSON.stringify(this.value)) return
        this.$emit('input', tracks)
      }
    }
  }
}
</script>

<style>

tr.odd>td {
    background-color: #0094d90f
  }

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-grab{
  flex-wrap: nowrap;
  cursor: grabbing;
}

.audio{
  cursor: pointer;
}
</style>
